import { Injectable } from '@angular/core';
import { Router } from '@angular/router'
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Token } from '@angular/compiler/src/ml_parser/lexer';

@Injectable({ providedIn: 'root' })
export class HeaderInter implements HttpInterceptor {
    constructor(private router: Router) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let token = window.localStorage.getItem('floaterstoken');
        token ? token = token : "";
        if (token) {

            req = req.clone({
                setHeaders: {
                    'Authorization': 'Bearer ' + token
                }
            });

        }
        return next.handle(req)
    }
}