import { Injectable } from '@angular/core';

@Injectable()
export class AppConstants {
    public static CONSTANTS = {
      currentEnvironment: "prod",
      prod: {
        firebase :{
          apiKey: "AIzaSyD0UFJFOezqn0sk9gVnRUdJ0sMa1Yi8PZE",
          authDomain: "gofloaters.firebaseapp.com",
          databaseURL: "https://gofloaters.firebaseio.com",
          projectId: "gofloaters",
          storageBucket: "gofloaters.appspot.com", 
          messagingSenderId: "187167465849"
        },
        baseurl: 'https://us-central1-gofloaters.cloudfunctions.net/api',
        loginurl: 'https://us-central1-gofloaters.cloudfunctions.net/api/login',
        razorPayKey: 'rzp_live_L4jnEFvzps4krf'
      },
      dev: {
        firebase: {
          apiKey: "AIzaSyDYYQUOaQ08YOogOvnhccMPHesAIlFNShI",
          authDomain: "gofloatersdev.firebaseapp.com",
          databaseURL: "https://gofloatersdev.firebaseio.com",
          projectId: "gofloatersdev",
          storageBucket: "gofloatersdev.appspot.com",
          messagingSenderId: "509881371385"
        },
        baseurl: 'https://us-central1-gofloatersdev.cloudfunctions.net/api',
        loginurl: 'https://us-central1-gofloatersdev.cloudfunctions.net/api/login',
        razorPayKey: 'rzp_test_87k0WW6Nk4NGoC'
      },
      local: {
        firebase: {
          apiKey: "AIzaSyDYYQUOaQ08YOogOvnhccMPHesAIlFNShI",
          authDomain: "gofloatersdev.firebaseapp.com",
          databaseURL: "https://gofloatersdev.firebaseio.com",
          projectId: "gofloatersdev",
          storageBucket: "gofloatersdev.appspot.com",
          messagingSenderId: "509881371385"
        },
        baseurl: 'https://localhost:5000/gofloatersdev/us-central1/api',
        loginurl: 'https://localhost:5000/gofloatersdev/us-central1/api/login',
        razorPayKey: 'rzp_test_87k0WW6Nk4NGoC'
      },
      localProd: {
        firebase: {
          apiKey: "AIzaSyD0UFJFOezqn0sk9gVnRUdJ0sMa1Yi8PZE",
          authDomain: "gofloaters.firebaseapp.com",
          databaseURL: "https://gofloaters.firebaseio.com",
          projectId: "gofloaters",
          storageBucket: "gofloaters.appspot.com",
          messagingSenderId: "187167465849"
        },
        baseurl: 'https://localhost:5000/gofloatersdev/us-central1/api',
        loginurl: 'https://localhost:5000/gofloatersdev/us-central1/api/login',
        razorPayKey: 'rzp_test_87k0WW6Nk4NGoC'
      },
      // baseurl: 'https://us-central1-gofloaters.cloudfunctions.net/api',
       baseurl: 'https://us-central1-gofloatersdev.cloudfunctions.net/api',
      // baseurl : 'http://localhost:5000/gofloatersdev/us-central1/api',
      loginServices: {
        verifylogin: '/spaceowner/login'
      },
      bookings: {
        spacelogin:'/spaceowner/login',
        forgotpassword: '/spaceowner/forgotPassword',
        getbooking:'/admin/getbooking',
        bookinglist: '/spaceowner/booking/list',
        getspaces: '/admin/getspace',
        spacephotos: '/space/photos',
        createspace: '/admin/createspace',
        updatespace: '/admin/updatespace',
        updatestatus: '/booking/update',
        revenuereport: '/space/revenuereport',
        foodreport: '/space/foodreport',
        getmetadata:'/getmetadata',
      },
      filestorage: {
        filepath: ''
      },
      fcm: {
        register: '/register/device'
      }
    };
}
