import { Injectable } from '@angular/core';
import * as firebase from 'firebase/app'
import '@firebase/messaging';
import * as _ from 'underscore';
import { HttpClient } from '@angular/common/http';
import { AppConstants } from '../app.constant';
import { NetworkProvider } from '../services/network';
import { AngularFireAuth } from 'angularfire2/auth';
import { Headers, RequestOptions, Http } from '@angular/http';


@Injectable()
export class FcmCloudMessagingService {

  private messaging = firebase.messaging();
  private unsubscribeOnTokenRefresh = () => { };
  private browser: any;
  serviceUrl: any;
  baseurl: any;
  options: any;
  constructor(
    private http: Http,
    private afAuth: AngularFireAuth,
    private network: NetworkProvider
  ) {
    this.baseurl = AppConstants.CONSTANTS[AppConstants.CONSTANTS.currentEnvironment].baseurl;
    this.serviceUrl = AppConstants.CONSTANTS.fcm;
  }

  public enableNotifications(user) {
    this.browser = this.detectBrowser();
    if ('serviceWorker' in navigator && 'PushManager' in window) {
      navigator.serviceWorker.register('./firebase-messaging-sw.js').then((registration) => {
        this.messaging.useServiceWorker(registration);
        return this.messaging.requestPermission().then(() => {
          this.setupOnTokenRefresh(user);
          return this.updateToken(user);
        }).catch(err => {
        });
      }).catch(err => {
      });
    }
  }

  public disableNotifications() {
    this.unsubscribeOnTokenRefresh();
    this.unsubscribeOnTokenRefresh = () => { };
  }

  private updateToken(user) {
    return this.messaging.getToken().then((currentToken) => {
      if (currentToken) {
        const device: any = _.findWhere(user.registeredSpaceOwnerDevices, { token: currentToken });
        if (!device) {
          const userDeviceDetail = {
            "userid": user.uid,
            "usertype": "spaceowner",
            "devicetoken": {
              "deviceType": "pwa",
              "browser": this.browser.title,
              "token": currentToken
            }
          }
          this.registerUserDevice(userDeviceDetail);
        } else {
          console.log("Token already available");
        }

        return;
      } else {
        console.log('No Instance ID token available. Request permission to generate one.');
      }
    }).catch(err => {
      console.log("Problem while getting the token");
    });
  }

  async registerUserDevice(userDeviceDetail: any) {
    const token = await this.afAuth.auth.currentUser.getIdToken();
    const headers = new Headers({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token });
    let options = new RequestOptions({ headers: headers });
    const url = this.baseurl + this.serviceUrl.register;
    return new Promise((resolve, reject) => {
      return this.http.post(url, JSON.stringify(userDeviceDetail), options)
        .map(res => res['json']())
        .subscribe(values => {
          console.log(values);
          resolve(values);
        }, err => {
          reject(err);
        });
    });
  }

  private setupOnTokenRefresh(user): void {
    this.unsubscribeOnTokenRefresh = this.messaging.onTokenRefresh(() => {
      this.updateToken(user);
    });
  }

  detectBrowser() {
    var ua_other, ua = navigator.userAgent,
      matched_browser = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];

    //Check for Edge browser
    var edge_check = ua.match(/(edge(?=\/))\/?\s*(\d+)/i) || [];
    if ("Edge" === edge_check[1]) {
      return {
        title: edge_check[1],
        ver: edge_check[2]
      }
    }

    //Check for other browsers
    return /trident/i.test(matched_browser[1]) ? (ua_other = /\brv[ :]+(\d+)/g.exec(ua) || [], {
      title: "IE",
      ver: ua_other[1] || ""
    }) : "Chrome" === matched_browser[1] && (ua_other = ua.match(/\bOPR\/(\d+)/), null != ua_other) ? {
      title: "Opera",
      ver: ua_other[1]
    } : (matched_browser = matched_browser[2] ? [matched_browser[1], matched_browser[2]] : [navigator.appName, navigator.appVersion, "-?"], null != (ua_other = ua.match(/version\/(\d+)/i)) && matched_browser.splice(1, 1, ua_other[1]), {
      title: matched_browser[0],
      ver: matched_browser[1]
    })
  };
}
