import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import { AppConstants } from '../app.constant';
import { NetworkProvider } from '../services/network';
import { AngularFireAuth } from 'angularfire2/auth';
import { Headers, RequestOptions, Http, Response } from '@angular/http';
import * as firebase from "firebase/app";
import { ShareService } from './share.service';
import { Router } from '@angular/router';
import { AngularFireDatabase } from 'angularfire2/database';
import * as _ from "lodash";


@Injectable()
export class LoginService {
  public serviceUrl: any = {};
  currentUser: any;
  baseUrl: any;
  options: any;
  private userdetails: any;
  constructor(private http: HttpClient, private http1: Http, private network: NetworkProvider,
    public _ANGFIRE: AngularFireAuth, private sharedService: ShareService, private router: Router,
    private db: AngularFireDatabase) {
    this.baseUrl = AppConstants.CONSTANTS[AppConstants.CONSTANTS.currentEnvironment].baseurl;
    this.serviceUrl = AppConstants.CONSTANTS.bookings;
  }
  async loginWithEmailAndPassword(email: string, password: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this
        ._ANGFIRE
        .auth
        .signInWithEmailAndPassword(email, password)
        .then((val: any) => {
          this
            .getSpaceOwnerDetails(val.user.uid)
            // .getSpaceOwnerDetails("xX548WzhWUcOShkLL2Ob5i7cL9E2")
            .then(async data => {
              //Debug
              window.localStorage.setItem('user', JSON.stringify(data));
              this.sharedService.setSpaceOwner(data);
              //Debug
              //   "displayName": "Shakthi",
              //   "email": "shakthi@makerstribe.in",
              //   "isSpaceOwner": true,
              //   "lastUpdatedByEmail": "user@gofloaters.com",
              //   "lastUpdatedTime": 1641473109477,
              //   "location": {
              //     "city": "Chennai",
              //     "country": "India"
              //   },
              //   "phoneNumber": "9884343409",
              //   "signupDate": 1565176074082,
              //   "signupSource": "Space Owner App",
              //   "uid": "dEclxJoiHEZ0igvB3xkCSndqE9k1",
              //   "updatetype": "welcomed"
              // };
              // window.localStorage.setItem('user', JSON.stringify(userData));
              // this.sharedService.setSpaceOwner(userData);
              // await this.getSpacesListForSpaceOwner("dEclxJoiHEZ0igvB3xkCSndqE9k1");
              // await this.getSpacesListForSpaceOwner(data.uid);
              this.sharedService.isLoggedIn = true;
              resolve(data);
            })
        })
        .catch((err: any) => {
          let message: any;
          if (err.code == "auth/network-request-failed") {
            message = 'Please connect to the internet';
          } else if (err.code == 'auth/invalid-email') {
            message = 'Please enter valid email';
          } else if (err.code == 'auth/user-not-found') {
            message = 'Please enter valid email and password';
          } else {
            message = 'Bad credentials';
          }
          reject(message);
        });
    });
  }

  async getSpaceOwnerDetails(userId): Promise<any> {
    return new Promise((resolve, reject) => {
      this.db.object('/users/' + userId)
        .query.once('value').then(async (snapshot) => {
          let result = snapshot.val();
          try {
            resolve(result);
          }
          catch (err) {
            console.log("Error fetching spaces list");
            reject(err);
          }
        },
          err => {
            console.log("Error fetching space owner details");
            reject(err);
          })
    });
  }
  setCurrentUser(user) {
    this.currentUser = user;
  }
  getCurrentUser() {
    if (this.currentUser) {
      return this.currentUser
    }
    else {
      return JSON.parse(window.localStorage.getItem('user'))
    }
  }
  forgotPassword(email) {
    return new Promise<any>((resolve, reject) => {
      this._ANGFIRE.auth.sendPasswordResetEmail(email)
        .then(function (user) {
          resolve(user);
        },
          err => {
            reject(err);
          });
    });
  }
  //Get Space Owner Spaces
  getSpacesListForSpaceOwner(ownerId) {
    return new Promise((resolve, reject) => {
      this.db.list('spaces', ref => ref.orderByChild("ownerId").equalTo(ownerId))
        .query.once('value').then((snapshot) => {
          let spaces = _.values(snapshot.val());
          // console.log("spaces ", spaces);
          // Get the spaces that have the space owner email id as the logged in user's email id
          let spaceOwner = this.sharedService.getSpaceOwner();
          this.db.list('spaces', ref => ref.orderByChild("ownerEmail").equalTo(spaceOwner.email))
            .query.once('value').then((snapshot) => {
              let spaces2 = _.values(snapshot.val());
              // console.log("spaces2 ", spaceOwner.email, spaces2);
              //Loop through spaces2 and check if the spaceId is there in spaces. If it is not there then insert it
              for (let i = 0; i < spaces2.length; i++) {
                let spaceIncluded = _.find(spaces, { spaceId: spaces2[i].spaceId })
                if (!spaceIncluded) {
                  spaces.push(spaces2[i]);
                }
              }
              // Get the spaces that have the manager email id as the logged in user's email id
              this.db.list('spaces', ref => ref.orderByChild("managerEmail").equalTo(spaceOwner.email))
                .query.once('value').then((snapshot) => {
                  let spaces3 = _.values(snapshot.val());
                  // console.log("spaces3 ", spaceOwner.email, spaces3);
                  //Loop through spaces2 and check if the spaceId is there in spaces. If it is not there then insert it
                  for (let i = 0; i < spaces3.length; i++) {
                    let spaceIncluded = _.find(spaces, { spaceId: spaces3[i].spaceId })
                    if (!spaceIncluded) {
                      spaces.push(spaces3[i]);
                    }
                  }
                  // console.log("spaces ", spaces);
                  this.sharedService.setSpaceList(spaces);
                  if (spaces.length == 0) {
                    this.sharedService.emptySpaces = true;
                  }
                  else {
                    this.sharedService.emptySpaces = false;
                    for (let i = 0; i < spaces.length; i++) {
                      if (spaces[i].acceptFoodCredits == true) {
                        this.sharedService.noFoodCreditSpaces = false;
                        break;
                      }
                      else {
                        this.sharedService.noFoodCreditSpaces = true;
                      }
                    }
                  }
                  resolve(spaces);
                });
            });
        },
          err => {
            reject(err);
          })
    });
  }
  logout() { }
  private handleErrorObservable(error: Response | any) {
    console.error(error.message || error);
    return Observable.throw(error.message || error);
  }
}
