import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AngularFireAuth } from 'angularfire2/auth';
import * as firebase from "firebase/app";
import { Observable } from 'rxjs';
import { LoginService } from './services/login.service';
import { ShareService } from './services/share.service';
import { LoaderService } from './sharedcomponents';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {

  constructor(
    public firebaseAuth: AngularFireAuth,
    private loginservice: LoginService,
    private sharedService: ShareService,
    private router: Router,
    private loader: LoaderService
  ) {

  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Observable<boolean> | Promise<boolean> {
    return new Promise((resolve, reject) => {
      firebase.auth().onAuthStateChanged((user: firebase.User) => {
        if (user) {
          resolve(true);
        } else {
          this.router.navigate(['/login']);
          resolve(false);
        }
      });
    });
  }
  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Observable<boolean> | Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (this.sharedService.isLoggedIn) {
        resolve(true);
      } else {
        this.loader.display(true);
        firebase.auth().onAuthStateChanged((user: firebase.User) => {
          if (user) {
            if (user && user.uid) {
              this
                .loginservice
                .getSpaceOwnerDetails(
                  user.uid)
                .then((data) => {
                  // this.loginservice.setCurrentUser(data);
                  this.sharedService.setSpaceOwner(data); 
                  window.localStorage.setItem('user',JSON.stringify(data));
                  this.sharedService.isLoggedIn = true;
                  this.loader.display(false);
                  resolve(true);
                })
            } else {      
              this.loader.display(false);
              this.router.navigate(['/login']);
              resolve(false);
            }

          } else {
            this.loader.display(false);
            this.router.navigate(['/login']);
            resolve(false);
          }
        });
      }
    });
  }
}