import { Injectable } from '@angular/core';
import { AngularFireAuth } from 'angularfire2/auth';
import { Router } from '@angular/router';
import {HttpClient } from '@angular/common/http';
import * as firebase from 'firebase';
import { AppConstants } from './app.constant';
import { NetworkProvider } from './services/network';
import { ShareService } from './services/share.service';
import { Headers, RequestOptions, Http } from '@angular/http';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  authState: any = null;
  bookingDate="today"
  baseUrl: any;
  serviceUrl: any;
  constructor(private afAuth: AngularFireAuth,
              private router: Router,
              public network:NetworkProvider,
              public http : HttpClient,
              public http1:Http,
              private sharedService : ShareService
              ) {
    this.baseUrl= AppConstants.CONSTANTS[AppConstants.CONSTANTS.currentEnvironment].baseurl;
    this.serviceUrl = AppConstants.CONSTANTS.bookings;
    this.afAuth.authState.subscribe((auth) => {
      this.authState = auth;
    });

  }

  get isUserAnonymousLoggedIn(): boolean {
    return (this.authState !== null) ? this.authState.isAnonymous : false;
  }

  get currentUserId(): string {
    return (this.authState !== null) ? this.authState.uid : '';
  }

  get currentUserName(): string {
    return this.authState['email'];
  }

  get currentUser(): any {
    return (this.authState !== null) ? this.authState : null;
    }

  get isUserEmailLoggedIn(): boolean {
    if ((this.authState !== null) && (!this.isUserAnonymousLoggedIn)) {
      return true
    } else {
      return false
    }
  }


  async signUpWithEmail(user: any, password: string) : Promise < any > {
    return new Promise((resolve, reject) => {
      this
        .afAuth
        .auth
        .createUserWithEmailAndPassword(user.email, password)
        .then((userAuth : any) => {
          this.authState = user;
          // console.log("this.authState",userAuth);
          this.updateUser(userAuth.user.uid,user).then(val => {
            resolve(userAuth);
          })
          .catch(err => {
            reject(err);
          })
          
        })
        .catch((err : any) => {
          let message;
          if (err.code == "auth/network-request-failed") {
            message = 'Please connect to the internet';
          } else if (err.code == 'auth/invalid-email') {
            message = 'Please enter valid email';
          } else if (err.code == 'auth/user-not-found') {
            message = 'Please enter valid email and password';
          } else if (err.code == 'auth/email-already-in-use') {
            message = 'Email id already exist. please try login';
          } else {
            message = 'Bad credentials';
          }
          reject({name: message, message});
        });
    });
  }

  async updateUser(uid : string,user: any) {
    let signupDate = new Date();
    let signupSource = "Space Owner App";
    //Updatetype is set as welcomed as we dont want to send welcome email to the space owner user
    //Location is hard coded as Chennai as of now. This has to be changed
    const userObj={
      uid:uid,
      displayName:user.displayName,
      updatetype:'welcomed',
      signupDate:signupDate.getTime(),
      signupSource:signupSource,
      email:user.email,
      isSpaceOwner:true,
      phoneNumber:user.phoneNumber,
      location:user.city,
    }
    console.log("User obj being updated ",userObj);
    const token = await this
      .afAuth
      .auth
      .currentUser
      .getIdToken();
    let headers = new Headers({
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token
      }); // ... Set content type to JSON
      let options = new RequestOptions({headers: headers});
    return new Promise((resolve, reject) => {
      return this
        .http1
        .post(this.baseUrl + "/updateuserdetails", userObj, options)
        .subscribe(values => {
          this.sharedService.setSpaceOwner(userObj);
          this.sharedService.isLoggedIn = true;   
          resolve(values.json());
        }, err => {
          resolve({})
        })
    })
  }
  signOut(): void {
    this.afAuth.auth.signOut().then(val => {
      this.sharedService.isLoggedIn = false;
      this.sharedService.setSpaceList([]);
      // this.loginService.setCurrentUser({});    
      this.sharedService.setSpaceOwner({});  
    });
    window.localStorage.clear();
    this.router.navigate(['/login']);   
  }

  async getMetaData()
{
  const url = this.baseUrl + this.serviceUrl.getmetadata;
  return new Promise((resolve, reject) => {
    return this.http.get(url).pipe(
      map((res:any) => res))
      .subscribe(
        values => {
          this.sharedService.setOperationalCities(values.operationalCities);
          resolve(values);
        },
        err => {
          reject(err);
        }
      );
  });
}
  
}
