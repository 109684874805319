import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Headers, RequestOptions, Http } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { AppConstants } from '../app.constant';
import { AngularFireAuth } from 'angularfire2/auth';
import * as firebase from 'firebase';
import { Router } from '@angular/router';
import { NetworkProvider } from '../services/network';
import { BehaviorSubject, Subscription } from 'rxjs';
import { AngularFireDatabase } from 'angularfire2/database';
import { ShareService } from './share.service';
import { LoginService } from './login.service';
import * as _ from "lodash";

@Injectable()
export class BookingService {
    url = 'api/books';
    serviceUrl: any;
    baseurl: any;
    options: any;
    public bookings: BehaviorSubject<Array<any>> = new BehaviorSubject([]);
    public redemptionsList: BehaviorSubject<Array<any>> = new BehaviorSubject([]);
    bookingListListener: Subscription;
    redemptionListListener: Subscription;
    currdate: Date;
    constructor(
        private http: HttpClient,
        private http1: Http,
        public network: NetworkProvider,
        private db: AngularFireDatabase,
        public _ANGFIRE: AngularFireAuth,
        private router: Router,
        private sharedservice: ShareService,
        private loginService:LoginService) {
        this.baseurl = AppConstants.CONSTANTS[AppConstants.CONSTANTS.currentEnvironment].baseurl;
        this.serviceUrl = AppConstants.CONSTANTS.bookings;
    }
    private handleErrorObservable(error: Response | any) {
        return Observable.throw(error);
    }
    landingPageDataLoad() {
        let spaces = this.sharedservice.getSpaceList();
        if (spaces.length != 0) {
            this.getBookingsListForSpaceOwner();
            // this.getFoodRedemptionsListForSpaceOwner();
        }
    }

    //Booking List
    getBookingsListForSpaceOwner() {
        let bookingsdata = [];
        let spaces = this.sharedservice.getSpaceList();
        // spaces = [{spaceId:"-NQG8jpWpPddNYUS0kzy"}];
        for (let i = 0; i < spaces.length; i++) {
            let spaceId = spaces[i].spaceId;
            //Change this to a once value fetch from a listener
            this.db.list('bookings', ref => ref.orderByChild("spaceId").equalTo(spaceId))
            .query.once('value').then((snapshot) => {
            // this.bookingListListener = this.db.list('bookings', ref => ref.orderByChild("spaceId").equalTo(spaceId))
            //     .valueChanges().subscribe(res => {
                    let res = _.values(snapshot.val());
                    // console.log("Bookings for ",spaceId,res);
                    
                    // console.log("Bookings for space ",spaceId,res);
                    if (bookingsdata.length == 0) {
                        bookingsdata = res;
                    }
                    else {
                        let temp = res;
                        temp.map((item: any) => {
                            for (let i = 0; i < bookingsdata.length; i++) {
                                if (item.bookingid == bookingsdata[i].bookingid) {
                                    bookingsdata.splice(i, 1)
                                }
                            }
                        });
                        bookingsdata = bookingsdata.concat(res);
                    }
                    this.bookings.next(bookingsdata);
                },
                    err => {
                        console.log();
                    });
        }
    }

    //Food Redemption List
    /* Redundant function as we dont do food credits
    getFoodRedemptionsListForSpaceOwner() {
        let redemptions = [];
        let spaces = this.sharedservice.getSpaceList();
        for (let i = 0; i < spaces.length; i++) {
            if (spaces[i].acceptFoodCredits == true) {
                let spaceId = spaces[i].spaceId;
                this.redemptionListListener = this.db.list('foodCreditRedeemptions', ref => ref.orderByChild("spaceId").equalTo(spaceId))
                    .valueChanges().subscribe(res => {
                        if (redemptions.length == 0) {
                            redemptions = res;
                        }
                        else {
                            let temp = res;
                            temp.map((item: any) => {
                                for (let k = 0; k < redemptions.length; k++) {
                                    if (item.foodCreditId == redemptions[k].foodCreditId) {
                                        redemptions.splice(k, 1);
                                    }
                                }
                            });

                            redemptions = redemptions.concat(res);
                        }
                        this.redemptionsList.next(redemptions);
                    },
                        err => {
                            console.log();
                        });
            }
        }
    }
    */
    //Booking Status Update
    changeBookingStatus(data) {
        return new Promise((resolve, reject) => {
            this.db.object('/bookings/' + data.id)
                .update(data).then((res: any) => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }


    requestBookingSettlement(bookingkey,data) {
        return new Promise((resolve, reject) => {
            this.db.object('/bookings/' + bookingkey)
                .update(data).then((res: any) => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    async getRevenueReport(value) {
        const token = await this._ANGFIRE.auth.currentUser.getIdToken();
        let headers = new Headers({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token });
        let options = new RequestOptions({ headers: headers });
        const url = this.baseurl + this.serviceUrl.revenuereport;
        return new Promise((resolve, reject) => {
            return this.http1.post(url, JSON.stringify(value), options)
                .map(res => res.json())
                .subscribe(values => {
                    resolve(values);
                }, err => {
                    reject(err);
                });
        });
    }

    async getFoodReport(value) {
        const token = await this._ANGFIRE.auth.currentUser.getIdToken();
        let headers = new Headers({
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
        });
        let options = new RequestOptions({ headers: headers });
        const url = this.baseurl + this.serviceUrl.foodreport;
        return new Promise((resolve, reject) => {
            return this.http1
                .post(url, JSON.stringify(value), options)
                .map(res => res.json())
                .subscribe(
                    values => {
                        resolve(values);
                    },
                    err => {
                        reject(err);
                    }
                );
        });
    }


    async getSpace(value) {
        const token = await this._ANGFIRE.auth.currentUser.getIdToken();
        let headers = new Headers({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token });
        let options = new RequestOptions({ headers: headers });
        const url = this.baseurl + this.serviceUrl.getspaces;
        let Body_data = {
            "id": value
        }
        return new Promise((resolve, reject) => {
            return this.http1
                .post(
                    url,
                    JSON.stringify(Body_data),
                    options,
                )
                .map(res => res.json())
                .subscribe(values => {
                    resolve(values);
                }, err => {
                    reject(err);
                });
        });
    }

    async createspace(value) {
        const token = await this._ANGFIRE.auth.currentUser.getIdToken();
        let headers = new Headers({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token });
        let options = new RequestOptions({ headers: headers });
        const url = this.baseurl + this.serviceUrl.createspace;
        return new Promise((resolve, reject) => {
            return this.http1
                .post(
                    url,
                    JSON.stringify(value),
                    options
                )
                .map(res => res.json())
                .subscribe(values => {
                    this.loginService.getSpacesListForSpaceOwner(value.ownerId).then(val => {
                        resolve(values);
                    })

                }, err => {
                    reject(err);
                });
        });
    }

    async updatespace(value) {
        const token = await this._ANGFIRE.auth.currentUser.getIdToken();
        let headers = new Headers({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token });
        let options = new RequestOptions({ headers: headers });
        const url = this.baseurl + this.serviceUrl.updatespace;
        return new Promise((resolve, reject) => {
            return this.http1
                .post(
                    url,
                    JSON.stringify(value),
                    options
                )
                .map(res => res.json())
                .subscribe(values => {
                    let user = this.sharedservice.getSpaceOwner();
                    this.loginService.getSpacesListForSpaceOwner(user.uid).then(val => {
                        resolve(values);
                    })
                }, err => {
                    reject(err);
                });
        });
    }

    // async getspacelist(value) {
    //     const token = await this._ANGFIRE.auth.currentUser.getIdToken();
    //     let headers = new Headers({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token });
    //     let options = new RequestOptions({ headers: headers });
    //     const url = this.baseurl + this.serviceUrl.getspaces;
    //     let Body_data = {
    //         "id": value
    //     }
    //     return new Promise((resolve, reject) => {
    //         return this.http1
    //             .post(
    //                 url,
    //                 Body_data,
    //                 options
    //             )
    //             .map(res => res.json())
    //             .subscribe(values => {
    //                 this.sharedservice.setSpaceList(values);
    //                 resolve(values);
    //             }, err => {
    //                 reject(err);
    //             });
    //     });
    // }

    async createOrAddSpacePhotos(value) {
        const token = await this._ANGFIRE.auth.currentUser.getIdToken();
        let headers = new Headers({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token });
        let options = new RequestOptions({ headers: headers });
        const url = this.baseurl + this.serviceUrl.spacephotos;
        return new Promise((resolve, reject) => {
            return this.http1
                .post(
                    url,
                    value,
                    options
                )
                .map(res => res.json())
                .subscribe(values => {
                    this.getSpace(value.ownerId).then(val => {
                        resolve(values);
                    })
                }, err => {
                    reject(err);
                });
        });
    }
    // Shyam >> We are now using the method in login service instead
    //Get Space Owner Spaces
    // getSpacesListForSpaceOwner(value) {
    //     return new Promise((resolve, reject) => {
    //         this.db.list('spaces', ref => ref.orderByChild("ownerId").equalTo(value))
    //             .query.once('value').then((snapshot) => {
    //                 let spaces = _.values(snapshot.val());
    //                 this.sharedservice.setSpaceList(spaces);
    //                 if (spaces.length == 0) {
    //                     this.sharedservice.emptySpaces = true;
    //                 }
    //                 else {
    //                     this.sharedservice.emptySpaces = false;
    //                     for (let i = 0; i < spaces.length; i++) {
    //                         if (spaces[i].acceptFoodCredits == true) {
    //                             this.sharedservice.noFoodCreditSpaces = false;
    //                             break;
    //                         }
    //                         else {
    //                             this.sharedservice.noFoodCreditSpaces = true;
    //                         }
    //                     }
    //                 }
    //                 resolve(spaces);
    //             },
    //                 err => {
    //                     reject(err);
    //                 })
    //     });
    // }
}