import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { LoaderService } from './sharedcomponents/index';
import { Router } from '@angular/router';
import { LoginService } from './services/login.service';
import { ShareService } from './services/share.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  public showLoader = false;
  private user : any;
  constructor(private loaderService: LoaderService,private router:Router, private cdr: ChangeDetectorRef,
    private loginService: LoginService, private sharedService : ShareService) {
  
  }
    ngOnInit() {
      this.loaderService.status.subscribe((val: boolean) => {
        if (val) {
          this.showLoader = true;
        } else {
          this.showLoader = false;
        }
    });
    this.checkLocal();
    }
    checkLocal(){
    // this.user = this.loginService.getCurrentUser();  
    this.user = this.sharedService.getSpaceOwner();
    if (!this.user) {
      this.router.navigate(['login'])
    }

    // let token = window.localStorage.getItem('floaterstoken');
      // if(!token){
      //   this.router.navigate(['login'])
      // }
      //   this.router.navigate(['landing'])
      // }else{
      //   this.router.navigate(['login'])
      // }
    }

    ngAfterContentChecked() 
    {
      this.cdr.detectChanges();
    }

  }
