import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { AuthService } from '../auth.service';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { throwMatDialogContentAlreadyAttachedError } from '@angular/material';

@Injectable()
export class AddspaceService {
    url = "api/books";
    constructor(private http: HttpClient, public authService:AuthService) { }
    addSpace(space): Observable<any> {
        return this.http.post(this.url, space)
            .map(this.extractData)
            .catch(this.handleErrorObservable)
    }
    private extractData(res: Response) {
        const body = res.json();
        return body['data'] || {};
    }
    private handleErrorObservable(error: Response | any) {
        this.authService.signOut();
        console.error(error.message || error);
        return Observable.throw(error.message || error);
    }
}