import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Headers, RequestOptions, Http } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { AppConstants } from '../app.constant';
import { AngularFireAuth } from 'angularfire2/auth';
import * as firebase from 'firebase';
import { Router } from '@angular/router';
import { NetworkProvider } from '../services/network';
import { BehaviorSubject, Subscription } from 'rxjs';
import { AngularFireDatabase } from 'angularfire2/database';
import { ShareService } from './share.service';
import { LoginService } from './login.service';
import * as _ from "lodash";

@Injectable()
export class EnquiryService {
    url = 'api/books';
    serviceUrl: any;
    baseurl: any;
    options: any;
    public enquiries: BehaviorSubject<Array<any>> = new BehaviorSubject([]);
    enquiriesListListener: Subscription;
    currdate: Date;
    constructor(
        private http: HttpClient,
        private http1: Http,
        public network: NetworkProvider,
        private db: AngularFireDatabase,
        public _ANGFIRE: AngularFireAuth,
        private router: Router,
        private sharedservice: ShareService,
        private loginService:LoginService) {
        this.baseurl = AppConstants.CONSTANTS[AppConstants.CONSTANTS.currentEnvironment].baseurl;
        this.serviceUrl = AppConstants.CONSTANTS.bookings;
    }
    private handleErrorObservable(error: Response | any) {
        return Observable.throw(error);
    }
    landingPageDataLoad() {
        let spaces = this.sharedservice.getSpaceList();
        if (spaces.length != 0) {
            this.getEnquiriesListForSpaceOwner();
        }
    }

    //Booking List
    getEnquiriesListForSpaceOwner() {
        let enquiriesdata = [];
        let spaces = this.sharedservice.getSpaceList();
        console.log("Spaces ",spaces);
        for (let i = 0; i < spaces.length; i++) {
            let spaceId = spaces[i].spaceId;
            // console.log("Fetching enquiries for ",spaceId);
            this.enquiriesListListener = this.db.list('enquiries', ref => ref.orderByChild("spaceId").equalTo(spaceId))
                .valueChanges().subscribe(res => {
                    // console.log("Enquiries for space ",spaceId,res);
                    if (enquiriesdata.length == 0) {
                        enquiriesdata = res;
                    }
                    else {
                        let temp = res;
                        temp.map((item: any) => {
                            for (let i = 0; i < enquiriesdata.length; i++) {
                                if (item.emailid == enquiriesdata[i].emailid) {
                                    enquiriesdata.splice(i, 1)
                                }
                            }
                        });
                        enquiriesdata = enquiriesdata.concat(res);
                    }
                    this.enquiries.next(enquiriesdata);
                },
                    err => {
                        console.log();
                    });
        }
    }

    //Food Redemption List

    // Enquiry Status Update
    changeEnquiryStatus(data) {
        return new Promise((resolve, reject) => {
            this.db.object('/enquiries/' + data.enquiryId)
                .update(data).then((res: any) => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }


    async getSpace(value) {
        const token = await this._ANGFIRE.auth.currentUser.getIdToken();
        let headers = new Headers({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token });
        let options = new RequestOptions({ headers: headers });
        const url = this.baseurl + this.serviceUrl.getspaces;
        let Body_data = {
            "id": value
        }
        return new Promise((resolve, reject) => {
            return this.http1
                .post(
                    url,
                    JSON.stringify(Body_data),
                    options,
                )
                .map(res => res.json())
                .subscribe(values => {
                    resolve(values);
                }, err => {
                    reject(err);
                });
        });
    }

    // async getspacelist(value) {
    //     const token = await this._ANGFIRE.auth.currentUser.getIdToken();
    //     let headers = new Headers({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token });
    //     let options = new RequestOptions({ headers: headers });
    //     const url = this.baseurl + this.serviceUrl.getspaces;
    //     let Body_data = {
    //         "id": value
    //     }
    //     return new Promise((resolve, reject) => {
    //         return this.http1
    //             .post(
    //                 url,
    //                 Body_data,
    //                 options
    //             )
    //             .map(res => res.json())
    //             .subscribe(values => {
    //                 this.sharedservice.setSpaceList(values);
    //                 resolve(values);
    //             }, err => {
    //                 reject(err);
    //             });
    //     });
    // }

}