import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpModule } from '@angular/http';

@Injectable({
  providedIn: 'root'
})
export class ShareService {
  private spaceList: any[] = [];
  lable: String;
  public isLoggedIn = false;
  public spaceOwner: any;
  public emptySpaces = false;
  public noFoodCreditSpaces = false;
  public title = new BehaviorSubject<String>('');
  citiesMetaData: any;
  activeCities: any[];
  constructor(private http: HttpModule) { }

  setLable(value) {
    this.lable = value
    this.title.next(this.lable);
  }
  getLable() {
    return this.lable
  }
  setSpaceList(list) {
    this.spaceList = list;
    window.localStorage.setItem('spaceList',JSON.stringify(list));
  }
  //
  getSpaceList() {
    if (this.spaceList.length == 0) {
      let spaceList = JSON.parse(window.localStorage.getItem('spaceList'));
      if (spaceList.length > 0) {
        this.spaceList = spaceList
      }
      return this.spaceList;
    } else {
      return this.spaceList;
    }
  }

  getSpaceOwner() {
    if (!this.spaceOwner) {
      return JSON.parse(window.localStorage.getItem('user'));
    }
    else {
      return this.spaceOwner;
    }
  }

  setSpaceOwner(info) {
    this.spaceOwner = info;
  }

  setOperationalCities(data)
  {
    this.citiesMetaData = data;
  }

  getActiveCities()
  {
    if(this.citiesMetaData)
    {
      this.activeCities = [];
      Object.keys(this.citiesMetaData).forEach((key, index) => {
        let data = this.citiesMetaData[key];
        data.forEach(city => {
          if(city.available)
          this.activeCities.push({
            name:city.name,
            value:city.value,
            cityId:city.cityId
          });
        });       
      });
      this.activeCities.sort(function(a,b) {
        return a.cityId - b.cityId
      });
      return this.activeCities;
    }
  }

}
