import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Headers, RequestOptions, Http } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { AppConstants } from '../app.constant';
import { AngularFireAuth } from 'angularfire2/auth';
import * as firebase from 'firebase';
import { Router } from '@angular/router';
import { NetworkProvider } from '../services/network';
import { BehaviorSubject } from 'rxjs';
import { AngularFireDatabase } from 'angularfire2/database';
import { ShareService } from './share.service';
import { LoginService } from './login.service';
import * as _ from "lodash";

@Injectable()
export class FoodCreditService {
    url = 'api/books';
    serviceUrl: any;
    baseurl: any;
    options: any;

    currdate: Date;
    constructor(
        private http: HttpClient,
        private http1: Http,
        public network: NetworkProvider,
        private db: AngularFireDatabase,
        public _ANGFIRE: AngularFireAuth,
        private router: Router,
        private sharedservice: ShareService,
        private loginService: LoginService) {
        this.baseurl = AppConstants.CONSTANTS[AppConstants.CONSTANTS.currentEnvironment].baseurl;
        this.serviceUrl = AppConstants.CONSTANTS.bookings;
        const token = window.localStorage.getItem('floaterstoken');
        const headers = new Headers({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token });
        this.options = new RequestOptions({ headers: headers });
    }
    private handleErrorObservable(error: Response | any) {
        return Observable.throw(error);
    }
}
