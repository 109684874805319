import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  
  {path:"",redirectTo:"landing", pathMatch :"full"},
  {path:"login",loadChildren:"../app/login/login.module#LoginModule"},
  {path:"signup",loadChildren:"../app/signup/signup.module#SignupModule"},
  {path:"landing",loadChildren:"../app/landing/landing.module#LandingModule"},

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
