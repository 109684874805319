import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { HttpModule } from '@angular/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {MatInputModule} from '@angular/material/input';
import { HttpClientModule } from '@angular/common/http';
import { AngularFireModule } from 'angularfire2';
import { AngularFireAuthModule } from 'angularfire2/auth';
import { AngularFireDatabaseModule } from 'angularfire2/database';
import { AppConstants } from './app.constant';
import { LoaderService } from './sharedcomponents';
import { NetworkProvider } from './services/network';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HeaderInter } from '../app/services/interceptor';
import { LoginService } from './services/login.service'; 
import { BookingService } from './services/booking.service';
import { EnquiryService } from './services/enquiry.service';
import { AddspaceService } from './services/addspace.service';
import { FcmCloudMessagingService } from './services/fcm-cloud-messaging.service';
import { SpinnerComponent } from './sharedcomponents/spinner/spinner.component';
import { AuthGuard } from './authguard.service';
import { FoodCreditService } from './services/food-credit.service';
import { AngularFireStorageModule } from 'angularfire2/storage';
import { DragulaModule } from 'ng2-dragula';
import { MatButtonModule } from '@angular/material/button';




(window as any)['global'] = window;

@NgModule({
  declarations: [
    AppComponent,
    SpinnerComponent,
  ], 
  imports: [
    BrowserModule,
    BrowserAnimationsModule, 
    AppRoutingModule,
    MatInputModule, 
    HttpClientModule,
    HttpModule,
    AngularFireModule.initializeApp(AppConstants.CONSTANTS[AppConstants.CONSTANTS.currentEnvironment].firebase),
    AngularFireAuthModule,
    AngularFireDatabaseModule,
    AngularFireStorageModule,
    DragulaModule.forRoot() ,
    MatButtonModule,
  ],
  providers: [LoaderService,
              NetworkProvider,
              LoginService,
              BookingService,
              EnquiryService,
              AddspaceService,
              FcmCloudMessagingService,
              AuthGuard,
              FoodCreditService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HeaderInter,
      multi: true
     },  
              ],
      
  bootstrap: [AppComponent],
  
  schemas:[
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class AppModule { }
